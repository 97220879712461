import { pageBlockUIMapper } from 'mappers/pageBlock';

import { LdsProps } from 'components/LdsBlock/models';

import { PageBlock } from './models';

export const ldsBlockMapper = ({ fields: { ldsContent }, page_block_ui }: PageBlock): LdsProps => ({
  ldsContent,
  pageBlockUI: pageBlockUIMapper(page_block_ui[0].ui),
});
